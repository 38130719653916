/* profile message start */
.ceoprofileimage{
    margin-top: -80px;
    object-position:left center !important;
}
.parulimage{
    object-position: right center;
   

}

.profile_bg{
    background-color: #3f74382a;
    border-radius: 50% 50% 49% 51% / 73% 74% 26% 27% ;
}
.image_rad{
    border-radius: 50% 50% 49% 51% / 73% 74% 26% 27% ;

}
@media (max-width: 661px) {
    .ceoprofileimage {
        margin-top: -60px;
    
    }
    .parulimage{
        margin-top: -30px !important;

    }
}

/* profile message end */
/* certificate start */
.certimain{
    border: 5px double var(--darkgreen--);
}
.certi-image{
    width: 100%;
    height: 450px;
    transition: all 0.3s ease-in-out;
}
.certimain:hover .certi-image {
    border: 5px solid var(--darkgreen--);
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.5);
}
@media (min-width: 992px) and (max-width: 1400px){
    .certi-image {
        height: 350px !important;
    }
}
/* certificate end */

/* solar video start */

.parent {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 13px;

}
.div1 {
    position: relative;
    grid-column: span 2 / span 2;
    grid-row: span 4 / span 4;
}
.div2 {
    position: relative;
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    grid-column-start: 3;
}
.div3 {
    background-color:#3f74382a;
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    grid-column-start: 5;
}
.div3box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 20px;
}
.div4 {
    position: relative;
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    grid-column-start: 7;
}
.div5 {
    background-color: whitesmoke;
    grid-column: span 4 / span 4;
    grid-row: span 2 / span 2;
    grid-column-start: 3;
    grid-row-start: 3;
}
.div7 {
    position: relative;
    grid-column: span 2 / span 2;
    grid-row: span 4 / span 4;
    grid-column-start: 7;
    grid-row-start: 3;
}
.div8 {
    position: relative;
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    grid-row-start: 5;
}
.div9 {
  background-image: url("../Images/product_bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    grid-column-start: 3;
    grid-row-start: 5;
}
.div10 {
    position: relative;
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    grid-column-start: 5;
    grid-row-start: 5;
}
.vbody {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: background-color 0.4s ease-in-out, transform 0.4s ease-in-out;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
}
.vmainbox:hover .vbody {
    background-color: rgba(0, 0, 0, 0.5);
}
.blink {
    transition: transform 0.4s ease-in-out;
    transform: scale(1);
}
.vmainbox:hover .vbody .blink {
    transform: scale(1.2);
    box-shadow: 0px 0px 10px 5px var(--lightgray--);
}
@media (max-width: 768px) {
    .parent {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    
    .div1, .div2, .div3, .div4, .div5, .div7, .div8, .div9, .div10 {
        grid-column: span 1 / span 1;
        grid-row: span 1 / span 1;
    }
}
/* solar video end*/

/* brouchers start */
.brouchers_bg {
    background-image: url("../Images/old-texture-newspapers-arrangement-high-angle_23-2149318878.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    box-shadow: inset 0px 0px 1500px 1500px rgba(0, 0, 0, 0.815);
    object-fit: fill;
}
/* brouchers end */
/* gallery start */

.galleryimage {
    position: relative;
    display: inline-block;
    transform: scale(1);
    overflow: hidden;
    cursor: pointer;
}
.image {
    width: 100%;
    height: 100%;
    border: 5px double var(--green--);
    display: block;
    transition: transform 0.3s;
}
.galleryimage::before,.galleryimage::after {
    content: "";
    position: absolute;
    width: 45%;
    height: 45%;
    border: 7px solid transparent;
    transition: width 2s, height 2s, border-color 0.3s;
    z-index: 1;
}
.galleryimage::before {
    top: 0;
    left: 0;
    border-top-width: 45%;
    border-left-width: 45%;
    border-top-color: var(--darkgreen--);
    border-left-color: var(--darkgreen--);
} 
.galleryimage::after {
    bottom: 0;
    right: 0;
    border-bottom-width: 45%;
    border-right-width: 45%;
    border-bottom-color: var(--darkgreen--);
    border-right-color: var(--darkgreen--);
}
.galleryimage:hover::before {
    width: 100%;
    height: 100%;
}
.galleryimage:hover::after {
    width: 100%;
    height: 100%;
}
.galleryimage:hover .image {
    transform: scale(1.1);
    transition: all 4s;
}
/* gallery end */