* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --green--: #4aab3d;
  --darkgreen--: #0c1c0a;
  --lightgray--: rgb(161, 161, 161);
  --white--: white;
}


.lightgray {
  color: var(--lightgray--);
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  /* background-color: #f1f1f1; */
  box-shadow: inset 3px 3px 10px 10px rgba(128, 128, 128, 0.308);
}

::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen--);
}

/* font style  start */
.dark_stroke {
  -webkit-text-stroke-width: 0.9px;
  stroke-width: 1px;
  -webkit-text-stroke-color: var(--green--);
  font-size: 55px;
  text-transform: capitalize
}

.font_stroke {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 0.9px;
  stroke-width: 1px;
  -webkit-text-stroke-color: var(--green--);
  font-size: 55px;
  text-transform: capitalize
}

.ftittle {
  font-size: 46px;
  margin-top: -42px;
  color: var(--darkgreen--);
  font-weight: 500;
  line-height: 56px;
  text-transform: capitalize;
}

.pera {
  font-size: 14px;
}
@media screen and (min-width: 991px) and (max-width: 1199px) { 
  .dark_stroke,
  .font_stroke {
    font-size: 49px;
  }
  .ftittle {
    font-size: 35px;
    margin-top: -24px !important;
    line-height: 38px !important;
  }
}
@media only screen and (max-width: 990px) {

  .dark_stroke,
  .font_stroke {
    font-size: 35px !important;
  }

  .ftittle {
    font-size: 35px;
    margin-top: -24px !important;
    line-height: 35px !important;
  }
}

@media only screen and (max-width: 550px) {

  .dark_stroke,
  .font_stroke {
    font-size: 28px !important;
  }

  .ftittle {
    font-size: 30px !important;
    margin-top: -19px !important;
    line-height: 30px !important;
  }

  .pera {
    font-size: 12px !important;
  }
}

/* font style  end */

/* btn hover start */
.button-48 {
  appearance: none;
  background-color: var(--white--);
  border: 2px solid var(--green--) !important;
  border-width: 0;
  box-sizing: border-box;
  color: var(--green--) !important;
  cursor: pointer;
  display: inline-block;
  font-family: Clarkson, Helvetica, sans-serif;
  font-size: 18px;
  opacity: 1;
  outline: 0;
  padding: 0.4em 1.3em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: capitalize;
  transition: opacity 0.3s cubic-bezier(.694, 0, 0.335, 1),
    background-color 0.3s cubic-bezier(.694, 0, 0.335, 1),
    color 0.3s cubic-bezier(.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  box-shadow: 0px 0px 10px 2px #0b5e0056;
}

.button-48:before {
  animation: opacityFallbackOut 1.1s step-end forwards;
  backface-visibility: hidden;
  background-color: var(--darkgreen--);
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path 1.1s cubic-bezier(.165, 0.84, 0.44, 1),
    -webkit-clip-path 1.1s cubic-bezier(.165, 0.84, 0.44, 1);
  width: 100%;
}

.button-48:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);

}

.button-48 span {
  z-index: 1;
  position: relative;
}

/* btn hover end */


/* image hover start */

.allimg {
  position: relative;
  overflow: hidden;
}

.allimg img {
  z-index: 1;
  position: relative;
}

.desc1 {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0) rotate(90deg);
  transition: transform 0.5s ease, background-color 0.6s ease;
  z-index: 2;
}

.desc2 {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0) rotate(90deg);
  transition: transform 0.5s ease, background-color 0.6s ease;
  z-index: 2;
  border-radius: 200px 2px 2px 2px;
  -webkit-border-radius: 200px 2px 2px 2px;
  -moz-border-radius: 200px 2px 2px 2px;
}

.allimg:hover .desc1 {
  transform: scale(1) rotate(0deg);
  background-color: #0c1c0a4a;
}

.allimg:hover .desc2 {
  transform: scale(1) rotate(0deg);
  background-color: #0c1c0a4a;
}

/* image hover end */


/* header start */
.bg_color {
  backdrop-filter: blur(15px) !important;
  background-color: rgba(0, 31, 15, 0.336) !important;
 
}

.logoset {
  width: 150px;
  height: 180px;
}
@media screen and (min-width: 991px) and (max-width: 1199px) { 
  .logoset {
    width: 118px;
    height: 180px;
  }
}
.lg_header .sec {
  color: var(--primarycolor--) !important;
  border-radius: 5px;
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 1;
}

.lg_header .sec::after {
  content: "";
  width: 100%;
  height: 0%;
  position: absolute;
  background-color: var(--darkgreen--);
  border-radius: 3px;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  z-index: -1;
}

.lg_header .sec:hover::after {
  height: 100%;
  top: 100;
  color: white;
}

.lg_header .sec:hover .nav-link {
  color: white !important;
}

.ofcv_header .nav-link {
  color: var(--green--) !important;
}

.ofcv_header .nav-link:hover,
.ofcv_header .nav-link:focus {
  background: var(--darkgreen--);
  color: white !important;
}

.lg_header .nav-link.active,
.ofcv_header .nav-link.active {
  background-color: var(--darkgreen--) !important;
  color: white !important;
  width: 100%;
  border-radius: 3px;
}

.lg_header .nav-link {
  color: white !important;
}

.indx {
  z-index: 9998;
}

.navbar-toggler-icon {
  background-color: white;
}

/* header end */

/* crousel start */
.slider_image {
  margin-top: -130px;
  background-image: url("./Assets/Images/footerimage.jpg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
}

/* crousel end */

/* Footer start */

.footer_bg {
  background-image: url("./Assets/Images/footerimage.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 1500px 1000px rgba(0, 0, 0, 0.89);
}

.footericon {
  border: 1.5px solid gray;
  transition: all 0.3s ease-in-out;
  border-radius: 10px 0px 12px 0px;
  font-size: 19px;
}

.footericon:hover {
  color: var(--white--) !important;
  border-radius: 50%;
  border: 1.5px solid white;
}

.facebookicon:hover {
  background-color: #1877f2 !important;
}

.instaicon:hover {
  background-image: linear-gradient(180deg,
      #833ab4,
      #c13584,
      #e1306c,
      #f77737,
      #ffdc80);
}

.linkicon:hover {
  background-color: #0077b5 !important;
}

.twittericon:hover {
  background-color: #ae0000 !important;
}

.link_hover {
  color: var(--lightgray--) !important;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.link_hover:hover {
  color: var(--white--) !important;
  border-bottom: 1px solid white;
}

.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  line-height: 1;
  opacity: .75;
  color: var(--green--);


}


.slick-next,
.slick-prev {
  top: 110%;
}

.slick-prev {
  left: 0%;
}

.slick-next {
  left: 12%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}



.modal-image {
  max-width: 80%;
  max-height: 100%;
  margin: 0 auto;
}

.close-modal {
  position: absolute;
  top: 0%;
  right: 20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.prev-btn,
.next-btn {
  background: transparent;
  border: none;
  color: white;
  font-size: 40px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev-btn {
  left: 5%;
}

.next-btn {
  right: 5%;
}



/* Footer end */

/* CALCULATOR START */
input {
  outline: none !important;
}

input::placeholder {
  color: black;
}

/* CALCULATOR END */

/* Client Logo start */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.partners-logo-slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.slide-track {
  display: flex;
  animation: scroll 40s linear infinite;
  width: calc(300px * 19);
}

.partners-logo-slider:hover .slide-track {
  animation-play-state: paused;
}

.slide {
  height: 100px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(95%);
  transition: filter 0.3s ease-in-out;
}

.slide img {
  height: 100px;
}

.slide:hover {
  filter: none;
}

@media only screen and (max-width: 576px) {
  .slide {
    width: 200px !important;
  }

}

/* Client Logo end */


/* landingpage start */
.landingimage,
.landingimage_product,
.landingimage_fullproducts,
.landingimage_service,
.landingimage_career,
.landingimage_profile,
.landingimage_contact {
  background-image: url("../src/Assets/Images/IMG-20230616-WA0034.jpg");
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  margin-top: -100px;
  box-shadow: inset 0px 0px 1500px 1000px rgba(0, 0, 0, 0.553);
}

.landingimage_product {
  background-image: url("../src/Assets/Images/projectimage.jpg");
}

.landingimage_service {
  background-image: url("../src/Assets/Images/serviceimage.jpg");
}

.landingimage_career {
  background-image: url("../src/Assets/Images/profitable.jpg");
}

.landingimage_profile {
  background-image: url("../src/Assets/Images/profileimg\ \(2\).jpg");
}

.landingimage_contact {
  background-image: url("./Assets/Images/contactusbg_image.jpg");
}
.landingimage_fullproducts{
  background-image: url("../src/Assets/Images/highlits2.jpg");
}

.textsizehead {
  padding-top: 10px;
}
@media screen and (min-width: 991px) and (max-width: 1199px) { 
  .landingimage,
  .landingimage_product,
  .landingimage_fullproducts,
  .landingimage_service,
  .landingimage_career,
  .landingimage_profile,
  .landingimage_contact {
   
    height: 100vh !important;
    width: 100%;
    margin-top: -128px;
    
  }
}
@media only screen and (max-width: 576px) {

  .landingimage_product,
  .landingimage_service,
  .landingimage_career,
  .landingimage_profile,
  .landingimage_contact,
  .landingimage_fullproducts {
    height: 60vh;
  }
  .landingimage{
    height: 68vh;
    
  }
  .textsizehead {
    padding-top: 65px !important;
  }
}

/* landingpage end */

/* Error page start */
.errimagebg{
  background-color: #a3b7b8;
}

.image404{
  width: 50%; 
   margin: 0 auto;
  }
  @media only screen and (max-width:600px){
    .image404{
      width: 90% !important;
    }
  }
/* Error page end */