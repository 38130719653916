.loadershow {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_form {
    display: flex;
    height: 100vh;
}

.sidebar {
    background-image: linear-gradient(#0a1d3d, #081f44, #1d3d70dc, #a5b7d3bc);
    width: 250px;
    position: relative;
    height: 100vh;
}

.fixed-sidebar {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    background-color: #2c3e50;
    height: 100vh;
}

.sidebar .logout-btn {
    position: absolute;
    bottom: 10px;
}

.main-content {
    flex-grow: 1;
    background-image: url("../Images/Adminpanelimage//add-data_bg.avif");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
}

input {
    box-shadow: none !important;
    box-shadow: 0px 0px 5px 3px lightgray !important;
}

.offcanvas-header .btn-close {
    filter: invert(1);
}

.sidebar-image {
    width: 70px;
    height: 70px;
}

.gradient-btn {
    background: linear-gradient(to left, #081e41, #081e41, #081e41dc, #0d244abc);
    padding: 10px 20px;
    transition: background-size 0.4s ease-in-out;
    background-size: 200% 100%;
    background-position: left;
}

.gradient-btn:hover {
    background: linear-gradient(to right, #081e41, #081e41, #081e41dc, #0d244abc);
    background-position: right;
}

.sidebar_menu {
    transition: all 0.4s ease-in-out;
}

.sidebar_menu:hover {
    color: grey !important;
}

.nav-item.active {
    color: grey !important;
    font-weight: bold !important;
}

.table {
    width: 100%;
}

.table th {
    border: 3px solid white !important;
}

.table td {
    border: 1px solid rgb(172, 172, 172) !important;
}

.table tbody tr:nth-child(even) td {
    background-color: rgba(211, 211, 211, 0.377);
}

.table tbody tr:nth-child(odd) td {
    background-color: rgba(211, 211, 211, 0.377);
}

/* LoginPage start */
.main_login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url("../Images/Adminpanelimage//login_bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0px 0px 1200px 120px rgba(0, 0, 0, 0.219);
    background-attachment: fixed;
}

.login_form {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.304) !important;
    width: 100%;
}

@media (min-width: 768px) {
    .login_form {
        width: 50%;
    }
}

/* LoginPage end */

/* table start */
.tablecolor {
    background-image: linear-gradient(#0a1d3d,
            #081f44,
            #1d3d70dc,
            #a5b7d3bc) !important;
}

/* table end */