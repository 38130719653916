

.card {
  background: #46863e57;
  border-radius: 0;
  min-height: 220px;
}

.imageoffsetline {
  outline: 3px solid white;
  outline-offset: -20px;
}

.tab2 .nav-tabs .nav-link {
  color: black;
  border: 2px solid var(--green--);
  border-radius: 0;

}

.tab2 .nav-tabs .nav-link.nav-link:hover {
  color: white;
  background: #000;
  border: 2px solid var(--green--);
}

.tab2 .nav-tabs .nav-link.active {
  color: white;
  background: #000;
  border: 2px solid var(--green--);
}

.nav {
  justify-content: space-evenly;
}

.tab1 .nav-item {
  margin: 10px 10px;
}


.shadow_green {
  box-shadow: 0px 0px 10px 4px rgb(147, 206, 147);
}

.tabs {
  overflow: hidden;
}

.bg_tab {
  background-color: rgb(178, 204, 178);
  cursor: pointer;
}

.tabs .nav-tabs {
  border: 0;
}

.tabs .nav-link {
  border: 0;
  padding: 15px 15px 15px 15px;
  transition: 0.3s;
  color: var(--darkgreen--);
  border-radius: 0;
  border-right: 5px solid transparent;
  font-weight: 700;
  font-size: 15px;
}

.tabs .nav-link:hover {
  color: var(--white--);
  border-color: var(--green--);
  background-color: var(--darkgreen--);
}

.tabs .nav-link.active {
  color: var(--white--);
  border-color: var(--green--);
  background-color: var(--darkgreen--);
}

.tabs .tab-pane.active {
  animation: fadeIn 0.5s ease-out;
}

.tabs .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.tabs .details p {
  color: color-mix(in srgb, #444444, transparent 20%);
}

.tabs .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .tabs .nav-link {
    border: 0;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .tabs .nav-link {
    border: 0;
    padding: 15px;
  }

  .tab2 .nav-tabs .nav-link.active {
    width: 100% !important;
  }

  .tab2 .nav-tabs .nav-link {
    width: 100% !important;
  }

  .tabs .nav-link {
    width: 100% !important;
  }

  .tab1 .nav-item {
    width: 100% !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* work & process start */
.work_bg {
  background-image: url("../Images//testi_bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

}

.opacity_font {
  color: rgba(153, 153, 153, 0.849);
}

.work {
  font-size: 100px;
  color: rgb(207, 231, 211);
}

@media only screen and (max-width: 761px) {
  .work {
    font-size: 60px;
  }
}

/* work & process end */
/* energy start */
.energy_icon {
  width: 80px;
  height: 80px;
  background-color: #F7FFE9 !important;
}

.energy-container {
  position: relative;
  overflow: hidden;
}

.energy {
  position: absolute;
  bottom: -98%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../Images//product_bg.jpg");
  background-position: center;
  background-size: cover;
  transition: bottom 0.5s ease-in-out;
}

.energy-container:hover .energy {
  bottom: 0;
}

.energy-text {
  transition: color 0.5s ease-in-out;
}

.image_energy_round {
  border-radius: 200px 2px 2px 2px;
  -webkit-border-radius: 200px 2px 2px 2px;
  -moz-border-radius: 200px 2px 2px 2px;
}

@media only screen and (max-width: 800px) {
  .image_energy_round {
    height: 350px !important;
  }
}

/* energy end */
/* Highlite images start */
.highlite_image {
  margin-top: 150px;
}

@media only screen and (max-width: 800px) {
  .highlite_image {
    margin-top: 50px;
  }
}

.image_hight {
  margin: 0;
  display: grid;
  place-content: center;

}

.gallery {
  --n: 1;
  --m: 5;
  --g: 1px;
  --f: 0.2;
  display: grid;
  gap: var(--g);
  width: 100vw;
  height: 70vh !important;
  grid-template-columns: repeat(var(--m), auto);

}

@media only screen and (max-width: 800px) {
  .gallery {
    height: 40vh !important;
  }
}

@media only screen and (max-width: 500px) {
  .gallery {
    height: 30vh !important;
  }
}

.gallery>img {
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  cursor: zoom-in !important;
  filter: grayscale(80%);
  transition: 0.35s linear;
}

.gallery img:hover {
  filter: grayscale(0);
  width: calc(100vh * var(--f) / var(--n));
  height: calc(100vw * var(--f) / var(--m));
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.modal .prev,
.modal .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  cursor: pointer;
}

.modal .prev {
  left: 2%;
}

.modal .next {
  right: 2%;
}

.modal .close {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 3rem;
  color: white;
  cursor: pointer;

}


/* Highlite images end */