/* product Highlites start */
.product_high {
  background-image: url("../Images/product_bg.jpg");
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
}
.container_sec {
  position: relative;
  width: 92%;
  height: 300px;
}
@media only screen and (max-width: 1000px) {
  .container_sec {
    width: 100%;
  }
}

.pro_image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay_sec {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(7, 17, 6, 0.7);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.container_sec:hover .overlay_sec {
  height: 100%;
}

.text {
  color: white;
  font-size: 20px;
  transition: all 0.5s ease-in-out;
}
.icon_color {
  color: var(--green--);
  font-size: 50px;
}

.header_slickslider .slick-slider {
  position: relative;
}

.header_slickslider .slick-prev,
.header_slickslider .slick-next {
  position: absolute;
  background-color: var(--green--) !important;
  margin: 20px;
  width: 60px;
  height: 45px;
  z-index: 1;
  padding-top: 3px !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
}
@media only screen and (max-width: 761px) {
  .header_slickslider .slick-prev,
  .header_slickslider .slick-next {
    display: none !important;
  }
}
.header_slickslider .slick-prev::before,
.header_slickslider .slick-next::before {
  padding-top: 10px !important;
  font-size: 22px;
  color: white;
}

.header_slickslider .slick-prev {
  top: -42%;
  left: 80%;
  transform: translateX(100%);
}

.header_slickslider .slick-next {
  margin-left: 8px;
  top: -42%;
  left: 80%;
  transform: translateX(0);
}

.header_slickslider .slick-prev:hover,
.header_slickslider .slick-next:hover,
.header_slickslider .slick-prev:focus,
.header_slickslider .slick-next:focus {
  background-color: #0b5e0070 !important;
  border: 1px solid var(--green--) !important;
}

/* product Highlites enb */

/* bout us start */

.satisfied-clients img {
  border-radius: 50%;
  margin-right: -10px;
  width: 40px;
  height: 40px;
}

/* bout us end */

.gridc1 {
  grid-area: header;
}
.gridc2 {
  grid-area: footer;
}

.grid-container {
  display: grid;
  align-items: end;
  grid-template-areas: "header header header header header footer footer footer";
  gap: 15px;
  padding: 10px 0px 10px 0px;
}
.animated-image {
  animation: upDown 5s ease-in-out infinite;
  display: block;
  margin: 0 auto;
}

@keyframes upDown {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-15%);
  }
  100% {
    transform: translateY(0%);
  }
}
.animated-image_slow {
  animation: rotate 5s ease-in-out infinite;
  display: block;
  margin: 0 auto;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* why choose us start */

.icon_circle {
  border-radius: 200px 200px 200px 0px;
  -webkit-border-radius: 200px 200px 200px 0px;
  -moz-border-radius: 200px 200px 200px 0px;
  height: 65px;
  width: 65px;
}
/* why choose us end */

/* testimonial start */

.slick-dots li button:before {
  font-size: 15px !important;
  color: var(--green--);
}
.slick-dots li button {
  color: var(--green--);
}

.slick-dots li.slick-active button:before {
  color: var(--white--);
}

.play-icon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-icon-wrapper {
  width: 80px;
  height: 80px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.play-icon-wrapper:hover {
  transform: scale(1.1);
}

.play-icon-wrapper .play_icon {
  font-size: 2rem;
  transition: transform 0.3s ease;
}

.play-icon-wrapper:hover .play_icon {
  transform: scale(1.1);
}
.video_image {
  height: 559px;
}
@media only screen and (max-width: 761px) {
  .video_image {
    height: 400px;
  }
}
.video_image img {
  outline: 1px solid white;
  outline-offset: -14px;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-18px);
  }

  100% {
    transform: translateY(0);
  }
}

.animated-image {
  display: block;
  margin: 0 auto;
  animation: upDown 3s ease-in-out infinite;
}

/* testimonial end */

/* service start */
/* home page service css */

.service_bg {
  background-image: url("../Images/hero-bg-abstract.jpg");
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0px 0px 1500px 1000px rgba(255, 255, 255, 0.596);
}

.bg_sercard {
  background-color: #d1e9cf;
  transition: all 0.3s ease-in-out !important;
}
.bg_sercard:hover {
  background-color: var(--darkgreen--);
  color: white !important;
}
.sapce_top {
  margin-top: 100px;
}

.margint {
  margin-top: -100px;
}
@media only screen and (max-width: 761px) {
  .margint {
    margin-top: 0px;
  }
  .sapce_top {
    margin-top: 0px;
    margin: 0px !important;
  }
}
/* service page css */
.service_icon {
  background-color: var(--darkgreen--);
  transition: all 0.3s ease-in-out;
}
.service_icon {
  background-color: var(--darkgreen--);
  border: 5px solid var(--white--);
}

.card_service {
  overflow: hidden;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out !important;
  border-radius: 10px;
}

.card_service:hover {
  box-shadow: #142e10 -10px 10px, rgba(35, 65, 31, 0.527) -20px 20px !important;
  border: 1px solid #142e10c5;
}
.card_service:hover .service_icon {
  background-color: var(--green--);
}
/* service end */

/* blog and news start  */
.datecss {
  background-color: var(--darkgreen--);
  width: fit-content;
  margin-top: -60px;
}
.blog_height {
  height: 245px;
}
/* blog and news end */

/* homeslider start */
.carousel {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: -100px !important;
}

.carousel-slide {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out, visibility 0s linear 1s;
  z-index: 0;
}

.carousel-slide.active {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease-in-out, visibility 0s linear 0s;
  z-index: 1;
}

.carousel-slide.initial {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

.column {
  background-repeat: no-repeat;
  background-size: 500% 100%;
  transform: translateY(-100%);
}

.carousel-slide.active .column.animate:nth-child(1),
.carousel-slide.initial .column.animate:nth-child(1) {
  animation: slide-down 1s ease-in-out forwards;
  background-position: 0% 0;
}

.carousel-slide.active .column.animate:nth-child(2),
.carousel-slide.initial .column.animate:nth-child(2) {
  animation: slide-down 1s ease-in-out forwards 0.25s;
  background-position: 25% 0;
}

.carousel-slide.active .column.animate:nth-child(3),
.carousel-slide.initial .column.animate:nth-child(3) {
  animation: slide-down 1s ease-in-out forwards 0.5s;
  background-position: 50% 0;
}

.carousel-slide.active .column.animate:nth-child(4),
.carousel-slide.initial .column.animate:nth-child(4) {
  animation: slide-down 1s ease-in-out forwards 0.75s;
  background-position: 75% 0;
}

.carousel-slide.active .column.animate:nth-child(5),
.carousel-slide.initial .column.animate:nth-child(5) {
  animation: slide-down 1s ease-in-out forwards 1s;
  background-position: 100% 0;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.carousel-content {
  position: absolute;
  border-left: 10px solid white;
  top: 38%;
  left: 15%;
  z-index: 2;
  opacity: 0;
  transform: translateY(50%);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  transition-delay: 2s;
}

.carousel-content.show {
  opacity: 1;
  transform: translateY(0);
}

.carousel-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 10px;
  background-color: white;
}

.carousel-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15%;
  height: 10px;
  background-color: white;
}
.sliderwidth {
  width: 80% !important;
}
@media screen and (min-width: 991px) and (max-width: 1199px) {
  .carousel {
    margin-top: -128px !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-content {
    top: 35% !important;
    left: 10%;
  }
  .sliderwidth {
    width: 80% !important;
  }
}

@media only screen and (max-width: 650px) {
  .carousel-content {
    top: 35% !important;
    left: 10%;
    z-index: 2;
    opacity: 0;
    transform: translateY(-50%);
    font-size: 25px !important;
  }
  .carousel {
    height: 70vh !important;
    object-fit: cover !important;
  }
  .sliderwidth {
    width: 90% !important;
  }
}

.slide1 .column {
  background-image: url("../Images/slide1.jpg");
  box-shadow: inset 0px 0px 1500px 1000px rgba(0, 0, 0, 0.589);
  object-fit: cover;
}

.slide2 .column {
  background-image: url("../Images/slide2.jpg");
  box-shadow: inset 0px 0px 1500px 1000px rgba(0, 0, 0, 0.589);
  object-fit: cover;
}

.slide3 .column {
  background-image: url("../Images/slide3.jpeg");
  box-shadow: inset 0px 0px 1500px 1000px rgba(0, 0, 0, 0.589);
  object-fit: cover;
}
.whatsappicon {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 10px;
  bottom: 15%;
  z-index: 9999999;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 450px) {
  .whatsappicon {
    width: 50px;
    height: 50px;
  }
}
/* homeslider end */

/* blog start */
#saeds:nth-child(Odd) {
  margin-top: -200px !important;
}

@media screen and (max-width: 992px) {
  #saeds:nth-child(Odd) {
    margin-top: 0px !important;
  }
}
/* blog end */
