.joinicon{
  color: var(--green--);
  transition: 0.7s ease-in-out; 

}
.pera_color{
    color: rgb(150, 158, 165);
    transition: 0.7s ease-in-out; 
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  overflow: hidden;
}
.ag-courses-item_link {
background-image: url("../Images/product_bg.jpg");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.ag-courses-item_link:hover ,.ag-courses-item_link:hover .joinicon{
  text-decoration: none;
  color: #FFF !important;
}

.ag-courses-item_link:hover .pera_color{
  text-decoration: none;
  color: #FFF !important;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: var(--green--);
  color: white !important;
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}
.tablewidth{
  width: 70%;
}
@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }

}


@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .tablewidth{
  width:fit-content !important;
}

}