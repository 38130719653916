.service-section {
  position: relative;
}

.left-bg-color {
  background-color: var(--darkgreen--); 
  width: 25%; 
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.service-container {
  width: 100%;
  position: relative;
  z-index: 2; 
}
@media only screen and (max-width: 991px){

.left-bg-color{
      width: 100%; 
}
.small_sizef{
    color:var(--white--) !important;
}
}

/* faq start */
.img_faq{
  background-image: url("../Images/IMG-20240917-WA0008.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 480px;

}

.accordion-button:not(.collapsed){
  background-color: white !important;
  color: var(--green--);

}
.accordion-button:focus {
  color: var(--green--);
box-shadow:  0 0 0.25rem rgba(248, 248, 248, 0.25);
}
.accordion-body{
  color: gray;
  font-size: 14px;
}

/* residentialsolar start */
.solarinfotbl{
    background-color: var(--green--);
}
.solarinfoimg {
    position: relative;
    overflow: hidden;
    border: 7px double var(--green--);
    border-radius: 30px 0px 30px 0px;
}
.solarinfoimg img {
    z-index: 1;
    position: relative;
}
.descr {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(0) rotate(90deg);
    transition: transform 0.5s ease, background-color 0.6s ease;
    z-index: 2;
}
.solarinfoimg:hover .descr {
    transform: scale(1) rotate(0deg);
    background-color: #0c1c0a4a;
}

/* residentialsolar ens */